import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styles from './styles.module.scss';

function Pagination({ currentPage, numPages }) {
  const hasPagination = !!currentPage;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const newPostPage =
    currentPage - 1 === 1
      ? 'blog'
      : `blog/page/${(currentPage - 1).toString()}`;
  const oldPostPage = `blog/page/${(currentPage + 1).toString()}`;

  return (
    <div className={styles.pagination}>
      {hasPagination && !isLast && (
        <Link to={oldPostPage} rel="next">
          « Older posts
        </Link>
      )}
      <div>
        [ <Link to="/archives">Archives</Link> ]
      </div>
      {hasPagination && !isFirst && (
        <Link to={newPostPage} rel="prev">
          Newer posts »
        </Link>
      )}
    </div>
  );
}

Pagination.defaultProps = {
  currentPage: null,
  numPages: null,
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
};

export default Pagination;
