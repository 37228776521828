import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import styles from './styles.module.scss';

function Nav() {
  return (
    <ul className={styles.navigation}>
      <li>
        <Link
          className={classnames(styles.navListItem, styles.navAbout)}
          to="/about"
        >
          About
        </Link>
      </li>
      <li>
        <Link
          className={classnames(styles.navListItem, styles.navArchive)}
          to="/archives"
        >
          Archives
        </Link>
      </li>
      <li>
        <a
          className={classnames(styles.navListItem, styles.navProjects)}
          href="https://www.scribbledwall.com/"
        >
          Projects
        </a>
      </li>
      <li>
        <Link
          className={classnames(styles.navListItem, styles.navContact)}
          to="/contact"
        >
          Contact
        </Link>
      </li>
    </ul>
  );
}

export default Nav;
