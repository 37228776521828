import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../Pagination';
import styles from './styles.module.scss';

function Footer({ pageContext }) {
  return (
    <div className={styles.footer}>
      <Pagination
        currentPage={pageContext && pageContext.currentPage}
        numPages={pageContext && pageContext.numPages}
      />

      <div className={styles.copyright}>
        Designed and developerd by{' '}
        <a href="https://www.scribbledwall.com/">Michael</a>. Copyright ©{' '}
        <a href="https://www.scribbledwall.com/">Michael Ly</a>.
      </div>
    </div>
  );
}

Footer.defaultProps = {
  pageContext: null,
};

Footer.propTypes = {
  pageContext: PropTypes.object,
};

export default Footer;
