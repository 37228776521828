import React from 'react';
import Page from '../components/Page';

function NotFoundPage() {
  return (
    <Page title="Page not found" slug="/">
      <p>Sorry, no posts matched your criteria.</p>
    </Page>
  );
}

export default NotFoundPage;
