import React from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.scss';

import Logo from './images/logo.gif';

function Hero() {
  return (
    <div className={styles.hero}>
      <Link to="/">
        <img src={Logo} className={styles.logo} alt="Ricedog" />
      </Link>
    </div>
  );
}

export default Hero;
