import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../seo';
import PageTitle from '../PageTitle';
import styles from './styles.module.scss';

import Hero from '../Hero';
import Nav from '../Nav';
import Footer from '../Footer';

function Page({ children, title, subtitle, slug, pageContext }) {
  return (
    <div className={styles.page}>
      <div className={styles.hero}>
        <Hero />
      </div>

      <div className={styles.container}>
        <div className={styles.nav}>
          <Nav />
        </div>

        <div className={styles.content}>
          <SEO title={title} />

          {title && slug && (
            <PageTitle title={title} subtitle={subtitle} slug={slug} />
          )}

          {children}
        </div>
      </div>

      <Footer pageContext={pageContext} />
    </div>
  );
}

Page.defaultProps = {
  title: null,
  subtitle: null,
  slug: null,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  slug: PropTypes.string,
  pageContext: PropTypes.object,
};

export default Page;
