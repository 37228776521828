import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classnames from 'classnames';
import styles from './styles.module.scss';

function PageTitle({ title, subtitle, slug }) {
  return (
    <Fragment>
      <h1
        className={classnames(styles.pageTitle, {
          [styles.withSubtitle]: subtitle,
        })}
      >
        <Link to={slug}>{title}</Link>
      </h1>

      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </Fragment>
  );
}

PageTitle.defaultProps = {
  subtitle: null,
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

export default PageTitle;
